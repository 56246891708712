import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './infra/PrivateRoute';

import PreLoader from './components/PreLoader/PreLoader';
import HomeCaracol from './pages/HomeCaracol/HomeCaracol';
import HomeTrilha from './pages/HomeTrilha/HomeTrilha';
import TourCaracol from './pages/TourCaracol/TourCaracol';
import TicketCaracol from './pages/TicketCaracol/TicketCaracol';
import Affiliates from './pages/Affiliates/Affiliates';
// import AboutUs from './pages/AboutUs/AboutUs';
import Checkout from './pages/Checkout/Checkout';
//import TourList from './pages/TourList/TourList';
import MyReservations from './pages/MyReservations/MyReservations';
import SlowBuyPage from './pages/SlowBuyPage/SlowBuyPage';
import NotFound from './pages/NotFound/NotFound';
import Profile from './pages/Profile/Profile';
import ProfileNew from './pages/ProfileNew/ProfileNew';
import ProfilePassword from './pages/ProfilePassword/ProfilePassword';
import ProfileForgotPassword from './pages/ProfileForgotPassword/ProfileForgotPassword';
import ProfileResetPassword from './pages/ProfileResetPassword/ProfileResetPassword';
import Login from './pages/Login/Login';
import Stores from './pages/Stores/Stores';
import Contact from './pages/Contact/Contact';
import DownloadCenter from './pages/DownloadCenter/DownloadCenter';
import ThePark from './pages/ThePark/ThePark';
import Sustainability from './pages/Sustainability/Sustainability';
import FAQ from './pages/FAQ/FAQ';
// import Memory from './pages/Memory/Memory';
import OurHistory from './pages/OurHistory/OurHistory';
// import EducateCableCar from './pages/EducateCableCar/EducateCableCar';
import ExclusiveBonde from './pages/ExclusiveBonde/ExclusiveBonde';
//import Promotion from './pages/Promotion/Promotion';

import Confirmation from './pages/Confirmation/Confirmation';
import HowToGet from './pages/HowToGet/HowToGet';
import WorkUs from './pages/WorkUs/WorkUs';
import AccessRulesPage from './pages/AccessRulesPage/AccessRulesPage';
/* import BlackFriday from './pages/BlackFriday/BlackFriday';
import BFProduct from './pages/BlackFriday/components/BFProduct/BFProduct'; */
import Regulation from './pages/BlackFriday/components/Regulation/Regulation';
import AboutUs from './pages/AboutUs/AboutUs';
import Compliance from './pages/Compliance/Compliance';
import CookiesCaracol from './pages/Cookies/CookiesCaracol/CookiesCaracol';
import TermsOfPurchaseCaracol from './pages/TermsOfPurchase/TermsOfPurchaseCaracol/TermsOfPurchaseCaracol';
import PrivacyNoticeCaracol from './pages/PrivacyNotice/PrivacyNoticeCaracol/PrivacyNoticeCaracol';
import TermsOfUseCaracol from './pages/TermsOfUse/TermsOfUseCaracol/TermsOfUseCaracol';
import Legal from './pages/Legal/Legal';

function Routes() {
    //const [changeRoutes, setChangeRoutes] = useState<number>(0);

    const [authentication, setAuthState] = useState({
        authenticated: false,
        initializing: true
    });

    useEffect(() => {
        const objAuth: any = localStorage.getItem('bpasiteAuth');

        if (objAuth !== null) {
            setAuthState({
                authenticated: true,
                initializing: false
            });
        } else {
            setAuthState({
                authenticated: false,
                initializing: false
            });
        }

    }, [setAuthState]);

    useEffect(() => {
        if (window.location.pathname === "/links") {
            window.location.assign("https://beacons.ai/parquedocaracol");
        }
    }, [])

    if (authentication.initializing) {
        return <div><PreLoader /></div>;
    }

    return (
        <Switch>
            <Route path="/" exact component={HomeCaracol} />
            <Route path="/dHJpbGhh" exact component={HomeTrilha} />

            {/* <Route path="/promotion/:slug:any" component={Tour} /> */}
            <Route path="/sobre-nos" exact component={AboutUs} />
            <Route path="/termos-de-uso" exact component={TermsOfUseCaracol} />
            <Route path="/aviso-de-privacidade" exact component={PrivacyNoticeCaracol} />
            <Route path="/termos/politicasprivacidade/" exact>
                <Redirect to="/aviso-de-privacidade" />
            </Route>
            <Route path="/termos-de-compra" exact component={TermsOfPurchaseCaracol} />
            <Route path="/cookies" exact component={CookiesCaracol} />
            <Route path="/contato" exact component={Contact} />
            {/* <Route path="/checkout" exact component={Checkout} />*/}
            <Route path="/parceiros" exact> <Affiliates /></Route>
            {/* <Route path="/atividades/:slug" exact component={TourList} /> */}
            <Route path="/compra-rapida/:slug" exact component={SlowBuyPage} />
            <Route path="/lojas" exact component={Stores} />
            <Route path="/login" exact component={Login} />
            <Route path="/nova-conta" exact component={ProfileNew} />
            <PrivateRoute exact path="/perfil" isAuthenticated={authentication.authenticated} component={Profile} />
            <Route path="/nova-senha" exact component={ProfilePassword} />
            <Route path="/esqueci-senha" exact component={ProfileForgotPassword} />
            <Route path="/resetar-senha/:token" exact component={ProfileResetPassword} />
            <Route path="/confirmacao-cadastro/:token" exact component={Confirmation} />
            <Route path="/minhas-atividades" exact component={MyReservations} />
            {/* <Route path="/relacoes-com-investidores" exact component={DownloadCenter} /> */}
            <Route path="/arquivos-juridicos-publico" exact component={Legal} />
            <Route path="/parque" exact component={ThePark} />
            <Route path="/sustentabilidade" exact component={Sustainability} />
            <Route path="/perguntas-frequentes" exact component={FAQ} />
            {/* <Route path="/memoria" exact component={Memory} /> */}
            <Route path="/nossa-historia" exact component={OurHistory} />
            {/* <Route path="/educa-bondinho" exact component={EducateCableCar} /> */}
            <Route path="/bonde-exclusivo" exact component={ExclusiveBonde} />
            <Route path="/regras-de-acesso" exact component={AccessRulesPage} />

            <Route path="/regulamento" exact component={Regulation} />
            <Route path="/trabalhe-conosco" exact component={WorkUs} />

            <Route path="/como-chegar" exact component={HowToGet} />

            <Route path="/termos/politicasprivacidade/" exact>
                <Redirect to="/aviso-de-privacidade" />
            </Route>    

                

            <Route exact path="/tour/:slug:any">
                <Redirect to="/" />
            </Route>

            <Route exact path="/ticket/:slug:any">
                <Redirect to="/" />
            </Route>

            <Route exact path="/compra-rapida/:slug">
                <Redirect to="/" />
            </Route>

            <Route path="/tour/:slug:any" component={TourCaracol} />
            <Route path="/ticket/:slug:any" component={TicketCaracol} />
            
            <Route exact path="/categoria">
                <Redirect to="/" />
            </Route>

            <Route path="/compliance" exact component={Compliance} />

            <Route path="/404" exact component={NotFound} />

            <Route exact >
                <Redirect to="/404" />
            </Route>

        </Switch>
    );
}
export default Routes;