import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Helmet from 'react-helmet';

import MailchimpSubscribe from "react-mailchimp-subscribe";

import "./HomeCaracol.scss";

//import Partners from "../../components/Partners/Partners";
import Newsletter from "../../components/Newsletter/Newsletter";

//import Explore from "../../components/Explore/Explore";

import PageDafault from "../../templates/PageDafault";

import BannerHomeCaracol from "../../components/Banners/BannerHomeCaracol/BannerHomeCaracol";
import HowToGetCaracol from "../HowToGetCaracol/HowToGetCaracol";
import BannerNewHistory from "./components/BannerNewHistory";

import BannerNovoMirante from '../../assets/img/home/novo_mirante.png';
import BannerObservatorio from '../../assets/img/home/observatorio.png';
import BannerDeck from '../../assets/img/home/deck.png';
import BannerQuiosques from '../../assets/img/home/quiosque_caracol.png';
import BannerPendulo from '../../assets/img/home/pendulo.png';
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const HomeCaracol = () => {
  const [t] = useTranslation();
  const postUrl = process.env.REACT_APP_MAILCHIMP;

  return (
    <div style={{ backgroundColor: "#EEEEEE" }}>
      {
        process.env.REACT_APP_CLIENT_NAME === "Bondinho"
          ?
          <Helmet>
            <html lang={i18next.language} />
            <title>{process.env.REACT_APP_SERVER_NAME}</title>
            <meta name="description" content="Compre ingressos para visitar o Parque Caracol em Gramado e conheça a melhor vista do Brasil." />
            <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

            {/* Essential META Tags */}
            <meta property="og:title" content={process.env.REACT_APP_SERVER_NAME} />
            <meta property="og:type" content="TouristAttraction" />
            <meta property="og:image" content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_LOGO}`} />
            <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

            {/* Non-Essential, But Recommended */}
            <meta property="og:description" content="Compre ingressos para visitar o Parque Caracol em Gramado e conheça a melhor vista do Brasil." />
            <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

            {/* Non-Essential, But Required for Analytics */}
            {/* <meta property="fb:app_id" content="your_app_id" /> */}

            <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
            <link rel="canonical" href={process.env.REACT_APP_SERVER_URL} />
          </Helmet>
          :
          <Helmet>
            <html lang={i18next.language} />
            <title>{process.env.REACT_APP_SERVER_NAME}</title>
            <meta name="description" content="Compre ingressos para visitar o Parque Caracol em Gramado e conheça a melhor vista do Brasil." />
            <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

            {/* Essential META Tags */}
            <meta property="og:title" content={process.env.REACT_APP_SERVER_NAME} />
            <meta property="og:type" content="TouristAttraction" />
            <meta property="og:image" content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_LOGO}`} />
            <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

            {/* Non-Essential, But Recommended */}
            <meta property="og:description" content="Compre ingressos para visitar o Parque Caracol em Gramado e conheça a melhor vista do Brasil." />
            <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

            {/* Non-Essential, But Required for Analytics */}
            {/* <meta property="fb:app_id" content="your_app_id" /> */}

            <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
            <link rel="canonical" href={process.env.REACT_APP_SERVER_URL} />
          </Helmet>
      }

      <PageDafault>
        <div className="content-header">
          <BannerHomeCaracol />
        </div>
        <div
          id="buy-product"
          className="container container-atividades my-outside pb-5"
        >
          <div data-category="listCategorie" className="mb-3">

            <h1 className="text-primary text-center my-inside" style={{ fontWeight: 600 }}>
              {
                i18next.language === "pt"
                  ?
                  'Novas atrações no Parque do Caracol'
                  :
                  i18next.language === "en"
                    ?
                    'New attractions at Parque do Caracol'
                    :
                    i18next.language === "es"
                      ?
                      'Nuevas atracciones en el Parque do Caracol'
                      :
                      'Novas atrações no Parque do Caracol'
              }
            </h1>
            <div className="bg-home-main-text">
              <p className="text-primary text-center mb-0">
                {
                  i18next.language === "pt"
                    ?
                    'Até o final de 2025 teremos muitas novidades para você ter uma experiência ainda mais inesquecível.'
                    :
                    i18next.language === "en"
                      ?
                      "By the end of 2025, we will have many new features for you to have an even more unforgettable experience."
                      :
                      i18next.language === "es"
                        ?
                        'Para finales de 2025, tendremos muchas novedades para que tengas una experiencia aún más inolvidable.  '
                        :
                        'Até o final de 2025 teremos muitas novidades para você ter uma experiência ainda mais inesquecível.'
                }
              </p>
            </div>
          </div>
        </div>

        <div className="pt-2">
          <h2 className="title-our-park text-primary text-center mb-5 px-3">
            {
              i18next.language === "pt"
                ?
                'Conheça as atrações do parque:'
                :
                i18next.language === "en"
                  ?
                  "Discover the park's attractions:"
                  :
                  i18next.language === "es"
                    ?
                    'Descubre las atracciones del parque:'
                    :
                    'Conheça as atrações do parque:'
            }

          </h2>
          <div className="container-md my-outside mb-5 home-video pt-3 pb-4">
            <div className="position-relative image-mirante">
              <Link
                to={{
                  pathname: "https://comunicados.parquecaracol.com.br/uma-nova-historia",
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="img-fluid"
                  src={BannerObservatorio}
                  alt="Banner Uma Nova História"
                />
                <div className="position-absolute rounded-8 bottom-0 top-0 end-0 start-0 text-white jumbotron jumbotron-custom-great custom-rounded">
                  <div className="text-content">
                    {
                      i18next.language === "pt"
                        ? (<><h2 className="p-4">Observatório</h2><p className="fading-text-on px-4">Sinta a emoção de estar na copa de uma araucária,<br />se conecte com a natureza e contemple a Cascata do Caracol.</p><small className="fading-text-off text-center pb-4" style={{ fontSize: "10px" }}>Imagem Meramente Ilustrativa</small>  <div className="saiba-mais" >Saiba mais</div></>)
                        : i18next.language === "en"
                          ? (<><h2 className="p-4">Observatory</h2><p className="fading-text-on px-4">Feel the thrill of being at the top of an araucaria tree, connect with nature,<br />and admire the Caracol Waterfall.</p><small className="fading-text-off" style={{ fontSize: "10px" }}>Image for Illustrative Purposes Only</small></>)
                          : i18next.language === "es"
                            ? (<><h2 className="p-4">Observatorio</h2><p className="fading-text-on px-4">Siente la emoción de estar en la copa de una araucaria,<br />conéctate con la naturaleza y contempla la Cascada del Caracol.</p><small className="fading-text-off" style={{ fontSize: "10px" }}>Imagen Meramente Ilustrativa</small></>)
                            : (<><h2 className="p-4">Observatório</h2><p className="fading-text-on px-4">Sinta a emoção de estar na copa de uma araucária,<br />se conecte com a natureza e contemple a Cascata do Caracol.</p><small className="fading-text-off  text-center pb-4" style={{ fontSize: "10px" }}>Imagem Meramente Ilustrativa</small> <div className="saiba-mais">Saiba mais</div></>)
                    }
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="container-md my-outside mb-5 home-video pt-3 pb-4">
          <Row className="px-4 px-md-3 mb-3 mb-md-5 justify-content-between">
            <Col className="mb-5" sm={12} md={4}>
              <Link
                to={{
                  pathname: "https://comunicados.parquecaracol.com.br/uma-nova-historia",
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="position-relative">
                  <img
                    className="w-100"
                    src={BannerQuiosques}
                    alt="Banner Uma Nova História"
                  />
                  <div className="position-absolute rounded-8 bottom-0 top-0 end-0 start-0  text-white jumbotron  jumbotron-custom-small custom-rounded">
                    <div className="text-content-small text-center">
                      {
                        i18next.language === "pt"
                          ? (<><h2>Novos quiosques com churrasqueira</h2> <p className="fading-text-on">Estrutura moderna e mais confortável, aqui você poderá reunir amigos e família para um delicioso churrasco em meio à tranquilidade do parque.</p> <small className="fading-text-off pb-4" style={{ fontSize: "10px" }}>Imagem Meramente Ilustrativa</small> <div className="saiba-mais">Saiba mais</div></>)
                          : i18next.language === "en"
                            ? (<><h2>New barbecue pavilions</h2> <p className="fading-text-on">A modern and more comfortable structure, here you can gather friends and family for a delicious barbecue amidst the tranquility of the park.</p> <small className="fading-text-off pb-4" style={{ fontSize: "10px" }}>Image for Illustrative Purposes Only</small> <div className="saiba-mais">Find out more</div></>)
                            : i18next.language === "es"
                              ? (<><h2>Nuevos quioscos con parrilla</h2> <p className="fading-text-on">Estructura moderna y más cómoda, aquí puedes reunir a tus amigos y familiares para una deliciosa parrillada en medio de la tranquilidad del parque.</p> <small className="fading-text-off pb-4" style={{ fontSize: "10px" }}>Imagen Meramente Ilustrativa</small> <div className="saiba-mais">Saber más</div></>)
                              : (<><h2>Novos quiosques com churrasqueira</h2> <p className="fading-text-on">Estrutura moderna e mais confortável, aqui você poderá reunir amigos e família para um delicioso churrasco em meio à tranquilidade do parque.</p> <small className="fading-text-off pb-4" style={{ fontSize: "10px" }}>Imagem Meramente Ilustrativa</small> <div className="saiba-mais">Saiba mais</div></>)
                      }
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
            <Col className="mb-5" sm={12} md={4}>
              <Link
                to={{
                  pathname: "https://comunicados.parquecaracol.com.br/uma-nova-historia",
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="position-relative">
                  <img
                    className="w-100"
                    src={BannerDeck}
                    alt="Banner Uma Nova História"
                  />
                  <div className="position-absolute rounded-8 bottom-0 top-0 end-0 start-0 text-white jumbotron jumbotron-custom-small custom-rounded">
                    <div className="text-content-small text-center">
                      {
                        i18next.language === "pt"
                          ? (<><h2>Deck do Moinho</h2><p className="fading-text-on">Uma vista mais próxima de mais uma das quedas d'água espetaculares do parque, proporcionando novas perspectivas para explorar o parque.</p><small className="fading-text-off pb-4" style={{ fontSize: "10px" }}>Imagem Meramente Ilustrativa</small> <div className="saiba-mais">Saiba mais</div></>)
                          : i18next.language === "en"
                            ? (<><h2>Moinho Deck</h2><p className="fading-text-on">A closer view of another one of the park's spectacular waterfalls, providing new perspectives to explore the park.</p><small className="fading-text-off pb-4" style={{ fontSize: "10px" }}>Image for Illustrative Purposes Only</small> <div className="saiba-mais">Find out more</div></>)
                            : i18next.language === "es"
                              ? (<><h2>Deck del Molino</h2><p className="fading-text-on">Una vista más cercana de otra de las cascadas espectaculares del parque, proporcionando nuevas perspectivas para explorar el parque.</p><small className="fading-text-off pb-4" style={{ fontSize: "10px" }}>Imagen Meramente Ilustrativa</small> <div className="saiba-mais">Saber más</div></>)
                              : (<><h2>Deck do Moinho</h2><p className="fading-text-on">Uma vista mais próxima de mais uma das quedas d'água espetaculares do parque, proporcionando novas perspectivas para explorar o parque.</p><small className="fading-text-off pb-4" style={{ fontSize: "10px" }}>Imagem Meramente Ilustrativa</small> <div className="saiba-mais">Saiba mais</div></>)
                      }
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
            <Col className="mb-5" sm={12} md={4}>
              <Link
                to={{
                  pathname: "https://comunicados.parquecaracol.com.br/uma-nova-historia",
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="position-relative">
                  <img
                    className="w-100"
                    src={BannerPendulo}
                    alt="Banner Uma Nova História"
                  />
                  <div className="position-absolute rounded-8 bottom-0 top-0 end-0 start-0 text-white jumbotron jumbotron-custom-small custom-rounded">
                    <div className="text-content-small text-center">
                      {
                        i18next.language === "pt"
                          ? (<><h2>Salto de Pêndulo</h2><p className="fading-text-on text-center">Mergulhe em uma experiência radical com<br />uma queda livre de 100 metros. Pura  adrenalina para quem busca emoções extremas em meio à natureza!</p><small className="fading-text-off pb-4" style={{ fontSize: "10px" }}>Imagem Meramente Ilustrativa</small> <div className="saiba-mais">Saiba mais</div></>)
                          : i18next.language === "en"
                            ? (<><h2>Pendulum Jump</h2><p className="fading-text-on">Dive into a radical experience with a 100-meter free fall. Pure  adrenaline for those seeking extreme thrills in the heart of nature!</p><small className="fading-text-off pb-4" style={{ fontSize: "10px" }}>Image for Illustrative Purposes Only</small> <div className="saiba-mais">Find out more</div></>)
                            : i18next.language === "es"
                              ? (<><h2>Salto del Péndulo</h2><p className="fading-text-on">Sumérgete en una experiencia radical con una caída libre de 100 metros. Pura  adrenalina para quienes buscan emociones extremas en medio de la naturaleza!</p><small className="fading-text-off pb-4" style={{ fontSize: "10px" }}>Imagen Meramente Ilustrativa</small> <div className="saiba-mais">Saber más</div></>)
                              : (<><h2>Salto de Pêndulo</h2><p className="fading-text-on fw-normal">Mergulhe em uma experiência radical com uma queda livre de 100 metros. Pura  adrenalina para quem busca emoções extremas em meio à natureza!</p><small className="fading-text-off pb-4" style={{ fontSize: "10px" }}>Imagem Meramente Ilustrativa</small> <div className="saiba-mais">Saiba mais</div></>)
                      }

                    </div>
                  </div>
                </div>
              </Link>
            </Col>
          </Row>
        </div>
        {/* <div className="container-md my-outside my-outside-our-park my-3 my-md-5">
          <ParkType /> */}
        {/* <iframe className="bg-blog" src="https://blog.bondinho.com.br/pagina-de-iframe/" title="Blog Bondinho" data-cookieconsent="ignore"></iframe> */}
        {/* </div> */}

        {/* <div className="bannerCallToAction my-outside">
        <Link to="#">
          <div className="bannerCall" style={{ background: `url(${process.env.REACT_APP_SERVER_BANNERCALL_TOP})` }}></div>
        </Link>
      </div>


        {/* HowToGet */}
        <HowToGetCaracol />
        {/* HowToGet */}

        {/*         <div className="container-md my-outside">
          <h2
            className="text-primary text-center my-inside text-uppercase"
          >
            {t("home.explore.title")}
          </h2>

          <Explore />
        </div> */}

        {/* <div className="container-fluid bg-secondary pb-5 px-0">
        <div className="container container-atividades container-tour">
          <div data-category="listCategorie">
            <h2 className="text-tour text-center pb-3 pt-5 py-md-5">{t('home.tours')}</h2>

            <ProductsCarrousel codCategory="CAT-QM34882"></ProductsCarrousel>
          </div>
        </div>
      </div> */}
        {/* <Partners /> */}
        <MailchimpSubscribe
          url={`${postUrl}`}
          render={({ subscribe, status, message }: any) => (
            <Newsletter
              status={status}
              message={message}
              onValidated={(formData: any) => subscribe(formData)}
            />
          )}
        />

        {/* <TopSellerTours />
      <Newsletter />*/}
      </PageDafault>
    </div>
  );
}

export default HomeCaracol;